.our-procurement {
    position: relative;

    .container {
        position: relative;
    }

    .main-wrapper {
        position: relative;
        max-width: 1048px;
        margin: auto;

        @include breakpoint-down(lg) {
            padding-bottom: 30px;
        }
    }

    .our-procurement-content-wrapper {
        width: calc(50% - 20px);
        height: calc(100% + 5px);
        position: absolute;
        bottom: 0;
        right: 0;
        background: $color-light-gray;
        z-index: 9;

        .swiper-slide {
            opacity: 0;
            pointer-events: none;

            &-active {
                opacity: 1;
            }

            .content-wrapper {

                .title {
                    margin-top: 0;
                }
            }
        }

        .swiper-button-next {
            right: -24px;
        }

        .swiper-button-prev {
            left: -24px;
        }

        .content-wrapper {
            padding: 30px 45px;

            .button-primary {
                margin-top: 35px;
            }
        }

        @include breakpoint-down(lg) {
            position: static;
            width: 100%;
            margin-bottom: 30px;

            .swiper-slide {
                width: 100% !important;
            }

            .content-wrapper {
                padding: 0;

                .title {
                    text-align: left;
                }
                .description {
                    text-align: left;
                }
            }
        }
    }

    .our-procurement-thumb {
        padding-bottom: 5px;
        .swiper-slide {
            opacity: 0.5;

            &-active {
                opacity: 1;
            }

            img {
                margin-bottom: 0;
                width: 98%;
            }
        }

        @include breakpoint-down(lg) {
            padding-bottom: 0;
            .swiper-wrapper {
                padding-bottom: 40px;
            }
        }
    }
}
