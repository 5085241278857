html {
    overflow: hidden;
    overflow-y: auto;
}
body {
    overflow: hidden;
    font-size: 16px;
    line-height: 1;
    font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;

    &.menu-open {
        height: 100vh;
        overflow: hidden;
    }
}

.container {
    padding: 0 25px;
    @include breakpoint-up(xxxl) {
        max-width: 1600px;
        margin: auto;
    }
}
.container-sm {
    padding: 0 25px;
    @include breakpoint-up(xxl) {
        max-width: 1320px;
        margin: auto;
    }
}

.img-fluid {
    width: 100%;
}

.section-heading {
    @include font-size-rem(65, 75);
    font-family: 'gt_super_display_bold';
    font-weight: 700;
    color: $color-primary;
    letter-spacing: -2px;

    span {
        color: $color-secondary;
    }

    @include breakpoint-down(xxxl) {
        @include font-size-rem(50, 57);
    }

    @include breakpoint-down(xl) {
        @include font-size-rem(47, 56);
    }

    @include breakpoint-down(md) {
        @include font-size-rem(32, 37);
        text-align: center;
        padding: 0 6px;
        letter-spacing: -1px;

        span {
            letter-spacing: 0;
        }
    }
}

.section-heading-sm {
    @include font-size-rem(65, 75);
    font-family: 'gt_super_display_bold';
    font-weight: 700;
    color: $color-primary;
    margin-bottom: 15px;

    span {
        color: $color-secondary;
    }

    @include breakpoint-down(xxxl) {
        @include font-size-rem(50, 57);
    }

    @include breakpoint-down(xl) {
        @include font-size-rem(47, 56);
    }

    @include breakpoint-down(md) {
        @include font-size-rem(32, 37);
        text-align: center;
        padding: 0 6px;
    }
}

.bg-light-gray {
    background-color: $color-light-gray;
}

.bg-snow-white {
    background-color: $color-snow-white;
}

.bg-transparent {
    background-color: transparent;
}

.bg-white {
    background-color: $color-white;
}

.white-header {
    background-color: $color-white;
}

// swiper
.swiper-container-horizontal  {
    > .swiper-pagination-bullets {
        .swiper-pagination-bullet {
            background: $color-primary;
            width: 12px;
            height: 12px;
            opacity: 1;
            margin-right: 20px;

            &-active {
                background: $color-secondary;
            }

            @include breakpoint-down(sm) {
                width: 10px;
                height: 10px;
                margin: 0 5px;
            }
        }
    }
}

.swiper-button-prev,
.swiper-button-next {
    background-color: $color-primary;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    border: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 1;
    cursor: pointer;

    &.transparent-btn {
        background-color: transparent;
        border: 2px solid $color-primary;
    }

    &.swiper-button-disabled {
        opacity: 0.5;
    }

    &::after {
        content: none;
    }
}

.swiper-button-prev {
    background-image: url(../images/icons/arrow.svg);

    &.transparent-btn {
        background-image: url(../images/icons/arrow-red.svg);
    }
}

.swiper-button-next {
    background-image: url(../images/icons/arrow.svg);
    transform: rotate(180deg);

    &.transparent-btn {
        background-image: url(../images/icons/arrow-red.svg);
    }
}

.navigation-y-center {
    position: relative;

    .swiper-navigation {
        position: absolute;
        transform: translateY(-50%);
        width: 100%;
        z-index: 1;
        display: flex;
        justify-content: space-between;

        .swiper-button-prev,
        .swiper-button-next {
            position: static;
            background-color: $color-primary;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 24px;
            border: 0;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            z-index: 1;
            cursor: pointer;
        
            &.swiper-button-disabled {
                opacity: 0.5;
            }
        
            &::after {
                content: none;
            }
        
            @include breakpoint-down(sm) {
                width: 34px;
                height: 34px;
                background-size: 18px;
            }
        }

        .swiper-button-prev {
            background-image: url(../images/icons/arrow.svg);
            transform: translateX(-120%);

            @include breakpoint-down(md) {
                transform: translateX(-50%);
            }
        }
        
        .swiper-button-next {
            background-image: url(../images/icons/arrow.svg);
            transform: translateX(120%) rotate(180deg);

            @include breakpoint-down(md) {
                transform: translateX(50%) rotate(180deg);
            }
        }
    }
}

.decorative-item4 {
    position: absolute;
    left: 0;
    top: -20%;
    max-width: 260px;
    z-index: 1;

    @include breakpoint-down(lg) {
        right: -3%;
        left: auto;
        max-width: 160px;
        top: -15%;
    }
}

.decorative-item5 {
    position: fixed;
    left: 0;
    top: -13%;
    max-width: 140px;
    z-index: 1;
}

body {
    &:has(.header-hidden){
        .popup-modal {
            .modal-content{
                margin-top: 0px;
            }
        }
    }
}

.popup-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 2;
    overflow: hidden; /* Prevent modal from scrolling */
  }
  
  .modal-content {
    max-width: 1130px;
    width: 92%;
    height: auto;
    max-height: calc(100% - 20px);
    border-radius: 16px;
    display: inline-block;
    margin: 100px 0 0;
    padding: 0;
    overflow: hidden; /* Prevent modal content from overflowing */
    position: relative;
    text-align: left;
    vertical-align: middle;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    display: flex;
    flex-direction: column;
  }
  
  .modal-content::-webkit-scrollbar {
    display: none;
  }
  
  .close-button {
    border-radius: 0.25rem;
    cursor: pointer;
    position: absolute;
    right: 20px;
    font-size: 1.2em;
    line-height: 1;
    padding: 0 0.2em 0.15em;
    text-align: center;
    transition: color .12s ease-in-out;
    width: 1.5rem;
  }
  
  .close-button:hover {
    color: var(--clr-main);
  }
  
  .show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
  }
  
  .ppTxt {
    padding: 32px;
    flex: 1; /* Allow the content to expand and take available space */
    overflow-y: auto; /* Enable vertical scrolling for ppTxt */
  }

  .ppTxt::-webkit-scrollbar {
    display: none;
  }
  
  .privacyHead {
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    padding: 30px;
    width: 100%;
    z-index: 3;
  }
  
  .privacyHead h6 {
    font-weight: 700;
    font-size: 20px;
    color: #000;
    line-height: 1.2;
  }
  
  .privacyTxt {
    margin-top: 60px; /* Adjust this value based on the height of your heading */
    flex: 1; /* Allow the content to expand and take available space */
  }
  
  .privacyItem {
    margin-bottom: 30px;
  }
  
  .privacyItem h6 {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    color: #000;
    line-height: 1.2;
  }
  
  .privacyItem p {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.6;
    color: #707070;
  }
  
  .privacyItem a {
    font-weight: 500;
    color: #23556C;
  }
  
  .privacyItem ul {
    list-style: disc;
    padding-left: 20px;
  }
  
  .privacyItem li {
    margin-bottom: 5px;
    list-style: disc;
    font-size: 16px;
    line-height: 1.6;
    color: #707070;
  }
  
  .privacyItem li strong {
    color: #000;
  }