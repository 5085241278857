.main-banner-section {
    position: relative;
    padding: 80px 0 40px;

    .decorative {
        position: absolute;
        left: 0;
        bottom: -10%;
        max-width: 260px;
        z-index: 12;

        &.decorative-item2 {
            display: none;
        }

        @include breakpoint-down(xl) {
            bottom: -20%;
            max-width: 250px;
        }

        @include breakpoint-down(lg) {
            bottom: -20%;
            max-width: 250px;

            &.decorative-item1 {
                max-width: 173px;
                bottom: unset;
                top: -25%;
            }

            &.decorative-item2 {
                display: block;
                max-width: 170px;
                left: auto;
                right: 0;
                bottom: -6%;
            }
        }

        @include breakpoint-down(lg) {
            &.decorative-item1 {
                max-width: 140px;
                top: -20%;
            }

            &.decorative-item2 {
                right: -16px;
            }
        }

        @include breakpoint-up(lg) {
            &.decorative-item3 {
                right: 0;
                left: auto;
                bottom: 4%;
            }
        }
    }

    @include breakpoint-down(sm) {
        padding: 40px 0;
        .container {
            padding: 0;
        }
    }
    .flex-container {
        display: flex;
        justify-content: center;
        gap: 120px;

        .flex-item {
            width: calc(70% - 60px);
        }

        @include breakpoint-down(xxl) {
            gap: 70px;
            .flex-item {
                width: calc(75% - 35px);
            }
        }

        @include breakpoint-down(xl) {
            gap: 40px;
            .flex-item {
                width: calc(60% - 20px);
            }
        }

        @include breakpoint-down(lg) {
            gap: 30px;
            flex-direction: row-reverse;

            .flex-item {
                width: 50%;
            }
        }
    }

    .heading-block {
        .section-heading {
            margin-bottom: 15px;
        }

        .subheading {
            @include font-size-rem(17, 27);
            color: $color-black;
        }

        @include breakpoint-down(xl) {
            .section-heading {
                @include font-size-rem(42, 49);
                text-align: left;
            }

            .subheading {
                @include font-size-rem(20, 24);
            }
        }

        @include breakpoint-down(md) {
            padding: 0 45px;
            .section-heading {
                @include font-size-rem(30, 34);
                text-align: center;
            }

            .subheading {
                @include font-size-rem(18, 28);
                text-align: center;
                margin-bottom: 0;
                padding: 0 32px;
            }
        }
    }
    .left-block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 110px;
        position: relative;

        @include breakpoint-down(xxl) {
            gap: 75px;
        }

        .cta-block {
            display: flex;
            flex-direction: column;
            max-width: 430px;
            margin-left: auto;
            margin-right: 18px;
            padding-bottom: 83px;
            .heading-with-cta {
                text-align: right;
                h4 {
                    @include font-size-rem(56, 65);
                    color: $color-primary;
                    margin-bottom: 42px;
                    text-align: right;
                    font-weight: 400;
                }

                .button-secondary {
                    margin-left: auto;
                }
            }

            @include breakpoint-down(xxxl) {
                max-width: 360px;
                padding-bottom: 55px;
                .heading-with-cta {
                    h4 {
                        @include font-size-rem(42, 50);
                    }
                }
            }

            @include breakpoint-down(xl) {
                .heading-with-cta {
                    h4 {
                        @include font-size-rem(30, 36);
                        margin-bottom: 15px;
                    }
                }
            }

            @include breakpoint-down(md) {
                margin-right: 0px;
                padding-bottom: 0;
            }

            @include breakpoint-down(sm) {
                .heading-with-cta {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: -40px;
                    h4 {
                        @include font-size-rem(26, 36);
                        margin-right: 25px;
                    }
                    .button-secondary {
                        position: absolute;
                        left: auto;
                        right: 0;
                        margin-right: 25px;
                    }
                }
            }
        }
    }

    .img-wrapper {
        position: relative;
        .play-icon {
            width: 100px;
            height: 100px;
            cursor: pointer;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @include breakpoint-down(lg) {
                width: 65px;
                height: 65px;
            }
        }
    }

    .decorative-logo {
        width: 252px;
        height: auto;
        position: absolute;
        left: -17%;
        bottom: 24px;

        @include breakpoint-down(xxl) {
            width: 180px;
        }

        @include breakpoint-down(xl) {
            width: 130px;
            left: -12%;
        }
        @include breakpoint-down(sm) {
            position: static;
            width: 96px;
            margin: 0 10px 0 auto;
        }
    }

    &.variant-1 {
        position: relative;
        background: url(../images/indian-chilies/indian-chilies-banner.jpg) no-repeat center;
        background-size: cover;
        min-height: 1200px;
        padding: 150px 0 120px;

        &::before{
            content: "";
            background: rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .flex-container {
            flex-direction: row;
            justify-content: flex-start;
        }

        .left-block {
            position: unset;
            z-index: 1;
        }

        .heading-block {
            .section-heading {
                color: $color-white;
            }
            .subheading {
                color: $color-white;
                padding: 0;
            }
        }

        .decorative-logo {
            position: absolute;
            left: unset;
            right: 4%;
            bottom: 90px;
        }

        @include breakpoint-down(xxxl) {
            min-height: 600px;
        }

        @include breakpoint-down(md) {
            .container {
                padding: 0 25px;
            }
            .heading-block {
                padding: 0;
                .section-heading {
                    text-align: left;
                }
                .subheading {
                    text-align: left;
                }
            }

            .flex-container {
                .flex-item {
                    width: 100%;
                }
            }
        }

        @include breakpoint-down(sm) {
            background: url(../images/indian-chilies/indian-chilies-banner.jpg) no-repeat center;
            background-size: cover;
            min-height: 785px;
            padding: 130px 0 120px;

            .heading-block {
                .section-heading {
                    text-align: center;
                }
                .subheading {
                    text-align: center;
                }
            }

            .decorative-logo {
                left: 30px;
                right: unset;
                bottom: 55px;
                margin: 0;
            }
        }
    }

    &.variant-2 {
        padding: 90px 0 25px;

        .decorative {
            &.decorative-item2 {
                display: unset;
                left: unset;
                right: 0;
                top: 0;
            }

            @include breakpoint-down(lg) {
                &.decorative-item1 {
                    max-width: 140px;
                    top: -11%;
                }
            }
        }

        .left-block {
            justify-content: flex-start;
            gap: 70px;
        }

        .right-block {
            position: relative;
        }

        .img-wrapper {
            position: absolute;
            top: 0;
        }

        @include breakpoint-down(sm) {
            padding: 40px 0;
        }

        @include breakpoint-down(md) {
            .container {
                padding: 0 25px;
            }

            .img-wrapper {
                position: static;
            }
            .flex-container {
                gap: 50px;
                flex-direction: column;
                .flex-item {
                    width: 100%;
                }
            }
            .heading-block {
                padding: 0;
                .section-heading {
                    text-align: left;
                }

                .subheading {
                    text-align: left;
                    padding: 0;
                }
            }

            .cta-block {
                flex-direction: row;
                margin-top: 30px;
                gap: 30px;
                .heading-with-cta {
                    position: static;
                    transform: translateY(0);

                    h4 {
                        margin-right: 0;
                    }

                    .button-secondary {
                        position: relative;
                        margin-right: 0;
                    }
                }
            }
            .decorative-logo {
                margin: 0;
                position: static;
            }
        }
    }
}
