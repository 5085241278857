.our-partners-section {
    h2 {
        @include font-size-rem(48, 55);
        font-family: 'gt_super_display_bold';
        font-weight: 700;
        text-align: center;
        color: $color-primary;
        margin-bottom: 50px;

        @include breakpoint-down(lg) {
            @include font-size-rem(26, 30);
            margin-bottom: 15px;
            padding: 0 40px;
        }
    }
    .swiper-slide {
        img {
            width: 100%;
        }
    }
}
