.contact-us {
    background: $color-snow-white;

    .heading-block {
        @include breakpoint-down(lg) {
            padding: 0 20px;
        }
        .section-heading {
            @include font-size-rem(70, 83);
            margin-bottom: 30px;
        }

        .subheading {
            margin-bottom: 50px;
        }

        @include breakpoint-down(lg) {
            .section-heading {
                @include font-size-rem(36, 46);
                margin-bottom: 10px;
                text-align: left;
                padding: 0;
            }

            .subheading {
                @include font-size-rem(16, 20);
                margin-bottom: 20px;
                text-align: left;
                padding: 0;
            }
        }
    }

    .left-block {
        justify-content: unset;
        gap: 0px;
    }

    .contact-form {
        .form-control {
            border: none;
            border-radius: 18px;
            padding: 20px 24px;

            @include breakpoint-down(lg) {
                padding: 19px 18px;
            }

            &::placeholder {
                color: $color-primary;
                opacity: 0.5;
            }
        }
        .thank-you-message {
            display: none;
        }
        .submit {
            background-color: $color-primary;
            border: none;
            padding: 15px 37px;
            border-radius: 80px;

            @include breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    @include breakpoint-down(lg) {
        .flex-container {
            flex-direction: column;

            .flex-item {
                padding: 0 20px;
                width: 100%;
            }
        }
    }
}
