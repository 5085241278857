// Settings
@import 'settings/settings';

// Utilities
@import './utilities/utilities.scss';
@import './icons/iconmoon.scss';

@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'swiper/swiper-bundle.css';
@import 'magnific-popup/src/css/main.scss';

// components
@import 'components/btn';
@import 'components/header';
@import 'components/footer';
@import 'components/common-slider-with-pagination';
@import 'components/subscribe-now';
@import 'components/banner';
@import 'components/our-story';
@import 'components/our-procurement';
@import 'components/our-partners';
@import 'components/our-women-workers';
@import 'components/contact-us';
@import 'components/horizontal-tabs';
@import 'components/banner-with-slider';
@import 'components/graph';
@import 'components/masonry-layout';
