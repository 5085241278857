.common-slider-with-pagination {
    padding: 60px 0;
    position: relative;

    &.show-decorative {
        .decorative-item {
            max-width: 260px;
            height: auto;
            position: absolute;
            right: -3%;
            top: -15%;
            z-index: 2;

            @include breakpoint-down(lg) {
                right: unset;
                top: unset;
                bottom: -100px;
                left: 0;
                max-width: 170px;
            }
        }
    }

    .container,
    .container-sm {
        position: relative;
    }

    .row {
        .block {
            margin-bottom: 20px;
            p {
                @include font-size-rem(17, 27);
                margin-bottom: 0;
                opacity: 0.5;
            }
        }
    }

    .generic-swiper {
        overflow-x: visible;

        &.generic-swiper-with-mobile-space {
            .swiper-wrapper .swiper-slide {
                a {
                    text-decoration: none;

                    .title {
                        @include font-size-rem(16, 17);
                        font-family: 'Poppins', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                        font-weight: 500;
                        text-decoration: none;
                        margin: 20px 0 0;         
                    }
                }

                .image-wrapper img {
                    box-shadow: 0 0 5px 0 #ccc;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }

        &.swiper-container-horizontal > .swiper-pagination-bullets {
            position: relative;
            padding-top: 60px;
            text-align: left;
        }

        @include breakpoint-down(sm) {
            // overflow-x: hidden;

            .swiper-button-prev,
            .swiper-button-next {
                display: block;
            }

            &.swiper-container-horizontal  > .swiper-pagination-bullets {
                width: 100%;
            }

            &-with-mobile-space {
                .swiper-slide {
                    .image-wrapper {
                        padding: 0 50px;
                    }
                }

                &.swiper-container-horizontal  {
                    .swiper-button-prev {
                        left: 25px;
                    }

                    .swiper-button-next {
                        right: 25px;
                    }
                }
            }
        }
    }

    .generic-swiper2 {
        overflow-x: hidden;

        .swiper-pagination {
            display: none;
        }

        @include breakpoint-down(sm) {
            padding-bottom: 50px;

            .swiper-pagination {
                display: block;
            }
        }
    }

    .teams-swiper {
        cursor: url(../images/icons/drag-cursor.svg), auto;

        .swiper-slide {
            height: auto;

            .card {
                height: 100%;

                .content-wrapper {
                    text-align: center;
                    margin-top: 20px;

                    .title {
                        @include font-size-rem(24, 27);

                        @include breakpoint-down(lg) {
                            @include font-size-rem(20, 24);
                        }
                    }
                }

                img {
                    // max-width: 255px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        @include breakpoint-down(md) {
            cursor: unset;
        }

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }

    .no-overflow-nav {
        @media screen and (min-width: 1366px) {
            .swiper-button-disabled {
                display: none;
            }
        }
    }

    .nav-y-slider-center {
        position: relative;

        .swiper-slide {
            height: auto;
        }
        .card {
            padding: 30px;
            background: $color-white;
            box-shadow: 0px 4px 50px rgba(173, 165, 188, 0.07);
            border-radius: 36px;
            height: 100%;

            &.achievements-card {
                box-shadow: none;
            }

            @include breakpoint-down(lg) {
                padding: 34px !important;
            }

            .quotes {
                @include font-size-rem(16, 27);
                font-weight: 400;
                color: rgba($color-black, 0.85);
                margin-bottom: 0;
                padding: 32px 0;
                position: relative;
                flex: 1;

                &::before {
                    content: url(../images/icons/quotes.svg);
                    margin-right: 5px;
                }
                &::after {
                    content: url(../images/icons/quotes.svg);
                    position: absolute;
                    margin-left: 5px;
                    transform: rotate(180deg);
                }
            }

            .d-flex {
                gap: 25px;
                align-items: center;
                margin-top: 25px;
                .profile {
                    width: 80px;
                    height: auto;
                    margin-bottom: 0;
                }
                .title-sm {
                    @include font-size-rem(24, 28);
                    font-family: 'gt_super_display_bold';
                    font-weight: 700;
                    margin: 0px;

                    @include breakpoint-down(lg) {
                        @include font-size-rem(20, 24);
                    }
                }

                .position {
                    color: rbga($color-black, 0.5);
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        .swiper-button-next {
            right: -60px;
            transform: translateY(-50%) rotate(180deg);

            @include breakpoint-down(sm) {
                right: -25px
            }
        }

        .swiper-button-prev {
            left: -60px;
            
            @include breakpoint-down(sm) {
                left: -25px
            }
        }
    }

    .para-with-cta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .description {
            max-width: 730px;
        }

        @include breakpoint-down(xl) {
            .description {
                max-width: 450px;
            }
        }
        @include breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;

            .button-primary {
                padding: 17px 20px;
            }

            .description {
                max-width: unset;
            }
        }
    }

    .swiper-wrapper {

        .swiper-slide {
            height: auto;

            .content-wrapper {
                margin-top: 20px;
            }
            
            .card {
                background: $color-light-gray;
                border-radius: 56px;
                border: none;
                padding: 30px;
                height: 100%;
                @include transitionAll2s;

                &:not(.achievements-card) {
                    &:hover {
                        background: $color-secondary;

                        .title,
                        .position {
                            color: $color-white;
                        }

                        .social-links {
                            li {
                                a {
                                    .icon {
                                        color: $color-secondary;
                                    }
                                }
                            }
                        }
                    }
                }

                @include breakpoint-down(xl) {
                    padding: 30px;
                }

                @include breakpoint-down(sm) {
                    padding: 50px 60px;
                    border-radius: 24px;

                    .social-links {
                        gap: 17px;
                        li {
                            width: 34px;
                            height: 34px;
                        }
                    }
                }
            }
            img {
                width: 100%;
                height: auto;
                border-radius: 10px;
            }

            .title {
                @include font-size-rem(30, 35);
                font-family: 'gt_super_display_bold';
                font-weight: 700;
                color: $color-primary;
                margin: 0;
            }

            .position {
                @include font-size-rem(16, 24);
                text-align: center;
            }

            .description {
                @include font-size-rem(16, 24);
                font-weight: 400;
                color: $color-black;
                margin-bottom: 0;
            }

            .social-links {
                display: flex;
                justify-content: center;
                flex-wrap: nowrap;
                gap: 25px;
                padding-top: 40px;
                cursor: pointer;

                >:not(:nth-child(3)) {
                    display: none;
                }

                li {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 39px;
                    height: 39px;
                    background-color: $color-white;
                    border-radius: 50%;
                    box-shadow: 0 6px 9px rgba(0, 0, 0, 0.3);
                    @include transitionAll3s;

                    a {
                        padding: 10px;
                        text-decoration: none;
                        .icon {
                            &::before{
                                font-size: 18px;
                                color: $color-black;
                            }
                        }
                    }
                }
            }
        }
    }

    @include breakpoint-down(xxl) {
        .swiper-wrapper {
            .swiper-slide {
                .content-wrapper {
                    margin-top: 20px;

                    a {
                        text-decoration: none;
                    }
                }
                
                .title {
                    @include font-size-rem(24, 30);
                }
            }
        }
    }

    @include breakpoint-down(sm) {
        padding: 64px 0 56px;
        .row {
            .block {
                p {
                    text-align: center;
                }
            }
        }

        .swiper-wrapper {

            .swiper-slide {
                .content-wrapper {
                    margin-top: 20px;
                }

                .title {
                    @include font-size-rem(26, 30);
                    text-align: center;
                    margin-bottom: 5px;
                }

                .description {
                    @include font-size-rem(14, 24);
                    text-align: center;
                }
            }
        }
    }
}

.our-procurement {
    .swiper-pagination-bullets {
        padding-top: 40px;
    }
}