.subscribe-now-section {
    background: url(../images/Footer/footer.jpeg) no-repeat center;
    background-size: cover;
    padding: 90px 0;
    position: relative;
    
    &::before {
        content: "";
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .overlay{
        position: inherit;
        z-index: 1;
    }

    .section-heading {
        color: $color-white;
        margin-bottom: 15px;
        text-align: left;
    }

    .subheading {
        @include font-size-rem(16, 27);
        color: $color-white;
    }

    .contact-us-block {
        display: flex;
        flex-direction: column;
        max-width: 360px;
        margin-left: auto;

        h5 {
            @include font-size-rem(36, 41);
            font-family: 'gt_super_display_bold';
            margin-bottom: 25px;
            color: $color-white;
        }

        .flex-item {
            display: flex;
            margin-bottom: 25px;

            .email {
                text-decoration: none;
                display: flex;
                align-items: center;
            }
            .icon {
                font-size: 24px;
                padding-right: 15px;
                color: $color-white;
            }

            p {
                @include font-size-rem(16, 27);
                color: $color-white;
                margin-bottom: 0;
            }
        }
    }

    @include breakpoint-down(lg) {
        padding: 40px 0 36px;
        background-size: cover;
        .section-heading {
            @include font-size-rem(32, 37);
        }

        .subheading {
            @include font-size-rem(16, 18);
        }
        .contact-us-block {
            margin-left: unset;
            margin-top: 130px;

            h5 {
                @include font-size-rem(24, 27);
                margin-bottom: 15px;
            }

            .flex-item {
                margin-bottom: 20px;

                p {
                    @include font-size-rem(16, 18);
                }
            }
        }
    }

    @include breakpoint-down(sm) {
        .contact-us-block {
            margin-top: 80px;
        }
    }
}
