@use 'sass:math';

@mixin font-size-rem($pixels, $line-height: '', $context: $em-base-font) {
    font-size: #{math.div($pixels, $context)}rem;

    @if $line-height != '' {
        line-height: #{math.div($line-height, $context)}rem;
    }
}

@mixin disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@mixin box-shadow {
    -webkit-box-shadow: 2px 3px 16px 3px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 2px 3px 16px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 3px 16px 3px rgba(0, 0, 0, 0.5);
}

// Breakpoints
@mixin breakpoint-up($point) {
    $grid-point: map-get($breakpoints, $point);
    @media screen and (min-width: $grid-point) {
        @content;
    }
}
@mixin breakpoint-down($point) {
    $grid-point: map-get($breakpoints, $point);
    @media screen and (max-width: ($grid-point - 1)) {
        @content;
    }
}

@mixin middle-align() {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin vertical-middle-align() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin horizontal-middle-align() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

//Transitions
@mixin transitionAll2s {
    transition: all ease-out 0.2s 0s;
}
@mixin transitionAll3s {
    transition: all ease-out 0.3s 0s;
}
@mixin transitionAll($sec) {
    transition: all #{$sec}s ease 0s;
}

@mixin rotate($angle) {
    -moz-transform: rotate($angle);
    -ms-transform: rotate($angle);
    -webkit-transform: rotate($angle);
    transform: rotate($angle);
}
