@font-face {
  font-family: 'icomoon';
  src:  url('/assets/src/fonts/icomoon.eot?cpr977');
  src:  url('/assets/src/fonts/icomoon.eot?cpr977#iefix') format('embedded-opentype'),
    url('/assets/src/fonts/icomoon.ttf?cpr977') format('truetype'),
    url('/assets/src/fonts/icomoon.woff?cpr977') format('woff'),
    url('/assets/src/fonts/icomoon.svg?cpr977#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
  
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
  color: #fff;
}
.icon-arrow-long:before {
  content: "\e901";
  color: #ed3237;
}
.icon-at-the-rate:before {
  content: "\e902";
  color: #fff;
}
.icon-call:before {
  content: "\e903";
  color: #fff;
}
.icon-email:before {
  content: "\e904";
  color: #fff;
}
.icon-facebook:before {
  content: "\e905";
  color: #fff;
}
.icon-hamburger:before {
  content: "\e906";
  color: #ed3237;
}
.icon-insta:before {
  content: "\e907";
  color: #fff;
}
.icon-linkedin:before {
  content: "\e908";
  color: #fff;
}
.icon-location:before {
  content: "\e909";
  color: #fff;
}
.icon-play .path1:before {
  content: "\e90a";
  color: rgb(17, 17, 17);
}
.icon-play .path2:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-quotes:before {
  content: "\e90c";
}
.icon-twitter:before {
  content: "\e90d";
  color: #fff;
}
