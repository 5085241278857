footer {
    padding: 70px 0;
    background-color: $color-red;

    .brand-logo {
        width: 100%;
        max-width: 163px;
        margin: auto;
    }
    
    .links {
        gap: 60px;
        margin: 40px 0;

        .nav {
            gap: 60px;
            .nav-link {
                @include font-size-rem(20, 23);
                font-weight: 400;
                color: $color-white;
                @include transitionAll2s;
            }
        }

        @include breakpoint-down(lg) {
            gap: 7px;
            margin: 30px 0;

            .nav {
                gap: 7px;
                justify-content: center;
            }
        }

        @include breakpoint-down(sm) {
            .nav {
                .nav-link {
                    @include font-size-rem(14, 16);
                }
            }
        }
    }

    .note {
        @include font-size-rem(18, 28);
        color: rgba($color-black, 0.5);
        text-align: center;
        max-width: 850px;
        margin: auto;
    }

    .social-links {
        // display: none;
        display: flex;
        justify-content: center;
        gap: 30px;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 55px;
            height: 55px;
            background-color: $color-secondary;
            border-radius: 50%;
            @include transitionAll3s;

            a {
                padding: 10px;
                text-decoration: none;
                .icon {
                    font-size: 24px;
                    color: $color-white;
                }
            }

            &:hover {
                background-color: $color-primary;
            }
        }

        @include breakpoint-down(lg) {
            justify-content: center;
        }

        @include breakpoint-down(sm) {
            
            li {
                width: 28px;
                height: 28px;
                a {
                    .icon {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .bottom-bar {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 50px;

        .copyright-text {
            @include font-size-rem(16, 27);
            color: $color-white;
            margin-bottom: 0;
            flex: 1;
        }

        .right-block {
            flex: 1;
            justify-content: flex-end;
            gap: 30px;

            @include breakpoint-down(lg) {
                gap: 30px;
                row-gap: 10px;
                justify-content: center;
            }

            li {

                &:not(:first-child){
                    list-style: disc;
                    color: $color-white;
                }

                a {
                    text-decoration: none;
                    color: $color-black;
                    @include font-size-rem(16, 27);
                    position: relative;

                    &:hover {
                        color: $color-secondary;
                    }
                }
            }
        }

        @include breakpoint-down(lg) {
            flex-direction: column;
            align-items: center;
            gap: 30px;
        }

        @include breakpoint-down(sm) {
            gap: 20px;
            margin-top: 30px;


            .right-block {
                li {
                    button {
                        @include font-size-rem(14, 16);
                    }
                }
            }

            .copyright-text {
                @include font-size-rem(14, 16);
                text-align: center;
            }
        }
    }

    .disclaimer-btn,.terms-of-use-btn,.privacy-policy-btn {
        background: transparent;
        border: none;
        color: $color-white;
        font-size: 1rem;
        line-height: 1.6875rem;
        position: relative;
        text-decoration: none;
    }

    @include breakpoint-down(sm) {
        padding: 40px 0 52px;

        .note {
            @include font-size-rem(12, 22);
        }
    }
}
