.our-story {
    position: relative;
    background: url(../images/came-from-cold/our-story-banner.png) no-repeat;
    background-size: cover;
    background-position: top;
    min-height: 400px;
    height: auto;
    background-color: $color-light-gray;
    padding: 60px 0;

    .img-wrapper {
        position: relative;
        img {
            width: 100%;
            height: auto;
        }
    }

    .content {
        .section-heading {
            margin-bottom: 0;
        }

        p {
            @include font-size-rem(16, 27);
            color: rgba($color-black, 0.85);
        }
    }

    @include breakpoint-down(md) {

        .content {
            text-align: center;

            p {
                @include font-size-rem(14, 24);
            }
        }
    }
}
