.button {
    @include font-size-rem(16, 21);
    display: inline-flex;
    align-items: center;
    gap: 10px;
    padding: 20px 37px;
    color: $color-primary;
    text-decoration: none;
    @include transitionAll2s;

    // &.button-transparent {
    //     background-color: transparent;
    //     border: 2px solid $color-primary;
    //     border-radius: 80px;
    // }

    &.button-primary {
        color: $color-white;
        background: $color-primary;
        border-radius: 80px;
        position: relative;

        &:hover {
            background-color: $color-secondary;
        }

        .icon {
            transform: rotate(-45deg);
            margin-top: 3px;
        }

        @include breakpoint-down(sm) {
            @include font-size-rem(16, 18);
            padding: 17px 37px;
            width: 100%;
            max-width: 380px;
            justify-content: center;

            .icon {
                margin-top: 0;
            }
        }
    }

    &.button-secondary {
        @include font-size-rem(36, 39);
        font-family: 'gt_super_display_medium';
        font-weight: 500;
        color: $color-secondary;
        background-color: transparent;
        position: relative;
        text-decoration: none;
        padding: 0;

        &:hover {
            color: $color-primary;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: -16px;
            width: 100%;
            height: 3px;
            background-color: $color-primary;
        }

        @include breakpoint-down(xl) {
            @include font-size-rem(30, 33);
        }

        @include breakpoint-down(sm) {
            @include font-size-rem(18, 20);

            &::after {
                width: 18px;
                height: 18px;
            }

            &::before {
                bottom: -10px;
            }
        }
    }
}
