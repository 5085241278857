@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@font-face {
    font-family: 'gt_super_display_light';
    src: url('../fonts/gt-super-display-light.eot');
    src: url('../fonts/gt-super-display-light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gt-super-display-light.woff2') format('woff2'),
        url('../fonts/gt-super-display-light.woff') format('woff'),
        url('../fonts/gt-super-display-light.ttf') format('truetype'),
        url('../fonts/gt-super-display-light.svg#gt_super_displaylight') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gt_super_display_regular';
    src: url('../fonts/gt-super-display-regular.eot');
    src: url('../fonts/gt-super-display-regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gt-super-display-regular.woff2') format('woff2'),
        url('../fonts/gt-super-display-regular.woff') format('woff'),
        url('../fonts/gt-super-display-regular.ttf') format('truetype'),
        url('../fonts/gt-super-display-regular.svg#gt_super_displayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gt_super_display_medium';
    src: url('../fonts/gt-super-display-medium.eot');
    src: url('../fonts/gt-super-display-medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gt-super-display-medium.woff2') format('woff2'),
        url('../fonts/gt-super-display-medium.woff') format('woff'),
        url('../fonts/gt-super-display-medium.ttf') format('truetype'),
        url('../fonts/gt-super-display-medium.svg#gt_super_displaymedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gt_super_display_bold';
    src: url('../fonts/gt-super-display-bold.eot');
    src: url('../fonts/gt-super-display-bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gt-super-display-bold.woff2') format('woff2'),
        url('../fonts/gt-super-display-bold.woff') format('woff'),
        url('../fonts/gt-super-display-bold.ttf') format('truetype'),
        url('../fonts/gt-super-display-bold.svg#gt_super_displaybold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'gt_super_display_super';
    src: url('../fonts/gt-super-display-super.eot');
    src: url('../fonts/gt-super-display-super.eot?#iefix') format('embedded-opentype'),
        url('../fonts/gt-super-display-super.woff2') format('woff2'),
        url('../fonts/gt-super-display-super.woff') format('woff'),
        url('../fonts/gt-super-display-super.ttf') format('truetype'),
        url('../fonts/gt-super-display-super.svg#gt_super_displaysuper') format('svg');
    font-weight: normal;
    font-style: normal;
}
