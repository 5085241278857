.our-women-workers {
    padding: 60px 0 25px;

    .left-block {
        padding-top: 70px;
        padding-right: 90px;

        h5 {
            @include font-size-rem(24, 27);
            font-family: "gt_super_display_bold";
            font-weight: 700;
            color: $color-primary;
            margin-bottom: 10px;
        }

        p {
            @include font-size-rem(16, 27);
            color: $color-black;
            margin-bottom: 20px;
        }

        ul {
            li {
                @include font-size-rem(16, 27);
                color: $color-black;
                margin-bottom: 5px;
            }
        }
    }

    .right-block {
        position: relative;

        .img-wrapper {
            position: absolute;
            top: 0;
            z-index: 1;
        }
    }

    @include breakpoint-down(xl) {
        .left-block {
            padding-top: 0;
        }

        .right-block {
            .img-wrapper {
                position: static;
            }
        }
    }

    @include breakpoint-down(lg) {
        padding: 60px 0;
        .left-block {
            padding-top: 0;
            padding-right: unset;

            h5 {
                @include font-size-rem(20, 22);
                text-align: center;
                padding: 0 20px;
            }

            p {
                text-align: center;
            }
        }

        .right-block {
            .img-wrapper {
                position: static;
            }
        }
    }
}
