/*---------------------------------
		Colors Variables
-----------------------------------*/

$color-white: #ffffff;
$color-black: #000000;
$color-primary: #32647b;
$color-secondary: #ed3237;
$color-red: #db2c1d;
$color-light-gray: #fafafa;
$color-blur-red: rgba(210, 42, 43, 0.65);
$color-snow-white: #fafafa;
$color-whisper: rgba(35, 85, 108, 0.05);