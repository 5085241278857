.gallery-section {
    padding: 70px 0 170px;
    @include breakpoint-down(lg) {
        padding: 60px 0 120px;
    }

    @include breakpoint-down(md) {
        padding: 60px 0 92px;
    }
    .gallery-wrapper {
        .gallery-inner {
            width: 104%;
            position: relative;
            left: -2%;
            display: flex;
        }
        ul {
            display: flex;
            flex-wrap: nowrap;
            column-gap: 34px;
            margin: 0;
            padding: 0;
            list-style-type: none;
        }
        li {
            // width: 25%;
            &:nth-child(n) {
                position: relative;
                top: 100px;
            }

            &:nth-child(2n) {
                position: relative;
                top: 0;
            }
            img {
                width: 100%;
                margin: 17px 0;
                border-radius: 24px;
            }
        }
        @include breakpoint-down(xl) {
            li {
                &:nth-child(n) {
                    top: 60px;
                }

                &:nth-child(2n) {
                    top: 0;
                }
            }
        }
        @include breakpoint-down(md) {
            ul {
                column-gap: 14px;
            }

            li {
                &:nth-child(n) {
                    top: 32px;
                }

                &:nth-child(2n) {
                    top: 0;
                }

                img {
                    margin: 7px 0;
                    border-radius: 15px;
                }
            }
        }
    }
    .gallery-video-wrapper {
        width: 100%;
        margin: 17px 0;
        position: relative;
        .gallery-video {
            width: 100%;
            height: 100%;
            border-radius: 24px;
            position: absolute;
            left: 0;
            top: 0;
            -o-object-fit: cover;
            object-fit: cover;
        }
        img {
            width: 100%;
            margin: 0 !important;
            opacity: 0;
        }
        @include breakpoint-down(lg) {
            margin: 7px 0;
            .gallery-video {
                border-radius: 15px;
            }
        }
    }
}
