.tabs-section {
    padding: 90px 0 30px;

    @include breakpoint-down(md) {
        padding: 60px 0;
    }

    .heading-block {
        .section-heading-sm {
            margin: 0 auto 30px;

            @include breakpoint-down(md) {
                margin: 0 auto 20px;
                padding: 0 25px;
            }
        }
    }

    .horizontal-tabs-container {
        .nav-tabs {
            border: none;
            margin-bottom: 60px;
            flex-wrap: nowrap;
            white-space: nowrap;
            overflow: auto hidden;
            border-bottom: 1px solid $color-primary;

            @include breakpoint-down(md) {
                margin-bottom: 30px;
            }

            &::-webkit-scrollbar {
                height: 0px;
            }

            .nav-link {
                @include font-size-rem(24, 27);
                font-weight: 700;
                color: $color-primary;
                @include transitionAll2s;
                border: none;
                padding: 40px 20px;
                margin: auto 20px;
                box-sizing: border-box;
                position: relative;

                &.active {
                    color: $color-secondary;
                }

                &:hover {
                    color: $color-secondary;
                }

                @include breakpoint-down(md) {
                    @include font-size-rem(18, 22);
                    padding: 20px;
                }
            }
        }
    }

    .common-slider-with-pagination {
        padding: 40px 0 0;
        .generic-swiper {
            overflow-x: visible;

            @include breakpoint-down(sm) {
                overflow-x: visible;

                .swiper-pagination {
                    display: none;
                }
            }
        }

        .swiper-slide {
            img {
                margin-bottom: 25px;
            }
        }
    }

    .tab-pane {
        .section-heading-sm {
            margin-bottom: 40px;
        }

        .primary-heading {
            @include font-size-rem(20, 26);
            color: $color-primary;
            font-weight: 700;
            margin: 0;
        }

        .secondary-heading {
            @include font-size-rem(25, 36);
            color: $color-secondary;
            font-weight: 700;
            margin: 0;
        }

        .title {
            @include font-size-rem(18, 22);
            color: #32647b;
            margin: 0;
            
            @include breakpoint-down(md) {
                @include font-size-rem(16, 20);
            }
        }

        .text {
            @include font-size-rem(18, 28);
            color: rgba($color-black, 0.85);
            display: block;
            margin-bottom: 10px;
        }

        .left-block,
        .right-block {
            width: 48%;
            
            @include breakpoint-down(md) {
                width: 100%;
            }
        }

        .card {
            border: none;
            img {
                margin-bottom: 25px;
                border-radius: 10px;
                height: auto;
                width: 100%;

                @include breakpoint-down(md) {
                    margin-bottom: 10px;
                }
            }
        }

        @include breakpoint-down(md) {
            .section-heading-sm {
                padding: 0;
                margin-bottom: 10px;
                text-align: left;
            }
            .primary-heading,
            .secondary-heading {
                @include font-size-rem(18, 23);
            }

            .text {
                @include font-size-rem(14, 17);
            }
        }
    }

    .grid-container {
        display: grid;
        grid-template-columns: calc(33.33% - 20px) calc(66.66% - 20px);
        gap: 40px;

        @include breakpoint-down(xl) {
            grid-template-columns: 100%;
        }

        .section-heading-sm {
            @include font-size-rem(40, 44);
            margin-bottom: 0;
        }

        .grid-item {
            img {
                border-radius: 10px;
                width: 100%;
                height: auto;
            }
        }
            
        .inner-grid-container {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;

            @include breakpoint-down(md) {
                grid-template-columns: repeat(1, 1fr);
                gap: 30px;
            }
        }
    }

    .description {
        @include font-size-rem(16, 20);
        color: $color-black;
        margin: 0;

        @include breakpoint-down(md) {
            @include font-size-rem(14, 17);
        }
    }
    .item {
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $color-secondary;
    }

    .package-type-container {
        .grid-container {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 15px;
            margin-top: 25px;
        }

        .grid-item {
            img {
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }

    .chillies-container {
        img {
            width: 40px;
        }

        @include breakpoint-down(md) {
            img {
                max-width: 100px;
            }
        }
    }

    .progress-item {
        margin-top: 30px;

        .progress {
            margin-bottom: 15px;
            overflow: visible;
        }
        .progress-bar {
            position: relative;
            background-color: $color-secondary;
            overflow: visible;
            border-radius: 4px;
        }

        .value {
            @include font-size-rem(18, 20);
            color: $color-secondary;
            position: absolute;
            right: 0;
            top: -30px;
        }

        .range {
            @include font-size-rem(18, 20);
            color: $color-secondary;
        }
    }
}
