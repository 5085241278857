header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    transition: all 0.5s cubic-bezier(0.25, 0.74, 0.22, 0.99);

    @include breakpoint-down(md) {
        height: auto;
    }

    &.white-header .nav-wrapper,
    .nav-wrapper {
        position: relative;
        margin: 10px auto;
        width: 100%;
        background: transparent;

        @include breakpoint-down(xl) {
            margin: 26px auto;
        }

        .logo-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) !important;

            .logo {
                max-width: 186px;
                width: 85%;
                height: auto;
            }
        }

        .nav-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include breakpoint-down(lg) {
                flex-direction: column;
                justify-content: flex-start;
            }
        }

        .nav-tabs,
        .nav-tabs-right {
            display: flex;
            font-weight: 600;
            font-size: 18px;
            list-style: none;
            padding: 0;
            border: 0;

            .nav-tab {
                padding: 10px 30px;

                &:last-child {
                    margin-left: 68px;
                }

                &.btn-contact {
                    border: 2px solid $color-primary;
                    background-color: $color-primary;
                    border-radius: 80px;
                    @include transitionAll3s;

                    a {
                        color: $color-white;
                    }
                }

                .nav-link {
                    @include font-size-rem(18, 21);
                    color: $color-primary;
                    font-weight: 400;
                    text-decoration: none;
                    position: relative;
                    padding: 0;
                    border: none;
                    @include transitionAll3s;
                }

                &:hover:not(.active, .btn-contact) {
                        background-color: $color-whisper;
                        border-radius: 50px;
                }

                &.active:not(.btn-contact) {
                        background-color: $color-whisper;
                        border-radius: 50px;
                }
            }
        }

        @include breakpoint-down(lg) {
            .nav-tabs {
                margin-top: 80px;
                .nav-tab {
                    padding: 10px 20px;
                }
            }
        }

        @include breakpoint-down(lg) {
            .logo-container {
                .logo {
                    width: 55%;
                    height: auto;
                }
            }
            .nav-container {
                position: fixed;
                overflow-y: auto;
                z-index: -1;
                top: 0;
                right: -80%;
                width: 80%;
                height: 100%;
                background: #fff;
                box-shadow: 0 6px 9px rgba(0, 0, 0, 0.3);
                @include transitionAll3s;
            }

            .nav-tabs,
            .nav-tabs-right {
                flex-direction: column;
                align-items: center;
                width: 80%;
                gap: 30px;
                margin-bottom: 30px;

                .nav-tab {
                    padding: 10px 30px;

                    &:last-child {
                        margin-left: 0;
                    }
                }
            }

            .menu-btn {
                position: relative;
                display: block;
                margin: 0;
                cursor: pointer;
                z-index: 2;
                padding: 10px;
                border-radius: 10px;

                .line {
                    display: block;
                    height: 2px;
                    background: $color-secondary;
                    width: 17px;
                    margin-left: auto;

                    &:nth-child(2) {
                        margin-top: 4px;
                        opacity: 1;
                        width: 22px;
                    }

                    &:nth-child(3) {
                        margin-top: 4px;
                        width: 12px;
                    }
                }
            }

            #menuToggle {
                &:checked {
                    + {
                        .menu-btn {
                            .line {
                                transition: transform 0.2s ease;
                                width: 22px;
                                &:nth-child(1) {
                                    transform: translate3d(0, 6px, 0) rotate(45deg);
                                }
                                &:nth-child(2) {
                                    transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
                                    opacity: 0;
                                }
                                &:nth-child(3) {
                                    transform: translate3d(0, -6px, 0) rotate(-45deg);
                                }
                            }
                        }
                    }
                    ~ {
                        .nav-container {
                            z-index: 1;
                            display: flex;
                            right: 0;
                        }
                    }
                }
            }
        }

        @include breakpoint-down(sm) {
            display: inline-block;
            padding: 15px 0;

            .logo-container {
                .logo {
                    width: 102px;
                    height: auto;
                }
            }

            .menu-btn {
                position: absolute;
                right: 25px;
                top: 50%;
                transform: translateY(-50%);
                padding: 0;
            }
        }
    }

    &.header-transparent {
        .nav-wrapper {
            .logo-container {
                .logo {
                    // filter: invert(1);
                    display: none;
                }

                .logo-white {
                    display: block;
                }
            }

            #menuToggle {
                &:checked {
                    + {
                        .menu-btn {
                            .line {
                                background: $color-secondary;
                            }
                        }
                    }

                    + {
                        .nav-container {
                            .nav-tabs {
                                .nav-tab {
                                    .nav-link {
                                        &::after {
                                            background-color: $color-secondary;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .menu-btn {
                .line {
                    background: $color-white;
                }
            }
            .nav-tabs,
            .nav-tabs-right {
                .nav-tab {
                    .nav-link {
                        color: $color-white;

                        &::after {
                            background-color: $color-white;
                        }

                        @include breakpoint-down(lg) {
                            color: $color-primary;
                        }
                    }

                    &.btn-contact {
                        border: 2px solid $color-black;
                        background-color: $color-black;

                        .nav-link {
                            color: $color-white;
                        }

                        &:hover {
                            background-color: $color-white;
                            border: 2px solid $color-white;

                            a {
                                color: $color-black;
                            }
                        }

                        @include breakpoint-down(lg) {
                            border: 2px solid $color-black;
                        }
                    }

                    &:hover:not(.active, .btn-contact) {
                        .nav-link {
                            color: $color-white;
                        }
                    }

                    &.active:not(.btn-contact) {
                        .nav-link {
                            &::after {
                                background-color: $color-white;

                                @include breakpoint-down(lg) {
                                    background-color: $color-secondary;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.header-top {
        background-color: $color-white !important;
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    }

    &.header-hidden {
        transform: translateY(-200px);
    }

    &.white-header {
        .logo-white {
            display: none;
        }
    }
}
