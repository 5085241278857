.farm-to-fork {
    padding-top: 88px;
    background: url(../images/farm-banner/farm-banner.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    &::before {
        background: rgba(0,0,0,.4);
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .section-heading,
    .subheading {
        color: $color-white;
        z-index: 1;
    }

    .subheading {
        @include font-size-rem(16, 27);
        max-width: 1050px;
        margin: 0 auto 16px;
    }

    @include breakpoint-down(md) {
        .subheading {
            @include font-size-rem(16, 26);
        }
    }

    .swiper-pagination {
        position: static;

        .swiper-pagination-bullet {
            background: $color-white !important;
            opacity: 0.5;
        }

        .swiper-pagination-bullet-active {
            opacity: 1 !important;
        }
    }

    .story-swiper {
        padding: 90px 90px 65px;
        // background: $color-blur-red;
        backdrop-filter: blur(2.5px);

        .swiper-wrapper {
            padding-bottom: 25px;
        }

        h5,
        p {
            color: $color-white;
        }

        h5 {
            @include font-size-rem(36, 42);
            font-family: 'gt_super_display_bold';
            font-weight: 700;
            margin: 0 0 20px;
        }

        p {
            @include font-size-rem(16, 27);
        }

        @include breakpoint-down(lg) {
            padding: 60px 30px 65px;
        }

        @include breakpoint-down(md) {
            padding: 50px 25px;
            text-align: center;
        }
    }
}
