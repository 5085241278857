.trading-graph {
    padding: 60px 0;
    position: relative;

    .subheading {
        @include font-size-rem(18, 28);
        color: $color-black;
        max-width: 1050px;
        margin: auto;
    }

    &.bg-snow-white {
        .graph-img {
            
            img {
                width: 100%;
                max-height: 100%;
                height: auto;
            }
        }
    }

    .graph-img {
        text-align: center;
        img {
            max-height: 500px;
            height: auto;
            width: auto;

            @include breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    @include breakpoint-down(md) {
        padding: 60px 0;

        .subheading {
            @include font-size-rem(16, 20);
        }
    }
}
